<!--卡密列表模式切换[卡密类型+使用状态]-->
<template>
    <el-dropdown>
    <el-button class="el-dropdown-link">
      {{lang['html.el-button.type']}}
      <el-icon class="el-icon--right">
        <arrow-down />
      </el-icon>
    </el-button>
        <template #dropdown>
            <el-dropdown-menu>
<!--                卡密类型[type]: 1=注册码 2=充值码 3=全部-->
                <el-dropdown-item @click="changeType(1)">{{lang['html.el-dropdown-item.changeType.1']}}</el-dropdown-item>
                <el-dropdown-item @click="changeType(2)">{{lang['html.el-dropdown-item.changeType.2']}}</el-dropdown-item>
<!--                // 卡密状态[status]: 0=未使用 1=已使用 3=全部-->
<!--                <el-dropdown-item @click="changeStatus(0)">{{lang['html.el-dropdown-item.changeType.3']}}</el-dropdown-item>-->
<!--                <el-dropdown-item @click="changeStatus(1)">{{lang['html.el-dropdown-item.changeType.4']}}</el-dropdown-item>-->
<!--                <el-dropdown-item @click="changeStatus(3)">{{lang['html.el-dropdown-item.changeType.5']}}</el-dropdown-item>-->
            </el-dropdown-menu>
        </template>
    </el-dropdown>

    <el-dropdown style="margin-left: 10px">
        <el-button class="el-dropdown-link">
            {{lang['html.el-button.status']}}
            <el-icon class="el-icon--right">
                <arrow-down />
            </el-icon>
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
<!--                // 卡密状态[status]: 0=未使用 1=已使用 3=全部-->
                <el-dropdown-item @click="changeStatus(0)">{{lang['html.el-dropdown-item.changeType.3']}}</el-dropdown-item>
                <el-dropdown-item @click="changeStatus(1)">{{lang['html.el-dropdown-item.changeType.4']}}</el-dropdown-item>
                <el-dropdown-item @click="changeStatus(3)">{{lang['html.el-dropdown-item.changeType.5']}}</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>

    <!--        margin: 上 右 下 左边-->
    <el-tag type="info" style="margin: 5px 0px 0px 10px">{{lang['html.el-tag.type']}}:{{getTypeStr}} {{lang['html.el-tag.status']}}:{{getStatusStr}}</el-tag>
</template>

<script>
export default {
    name: "keyList-modeMenu",
    emits:['changeData'],
    data(){
        return {
            type:999,
            status:999,
            lang:this.$lang.getLang('key','keyList-modeMenu')
        }
    },
    computed:{
        getTypeStr(){
            let msg = 'error';
            // 卡密类型[type]: 1=注册码 2=充值码 3=全部
            switch (Number(this.type)){
                case 1:
                    msg = this.lang['getTypeStr.type.1'];
                    break;
                case 2:
                    msg = this.lang['getTypeStr.type.2'];
                    break;
                case 3:
                    msg = this.lang['getTypeStr.type.3'];
                    break;
                default:
                    msg = this.lang['getTypeStr.type.default'];
            }
            return msg;
        },
        getStatusStr(){
            let msg = 'error';
            // 卡密状态[status]: 0=未使用 1=已使用 3=全部
            switch (Number(this.status)){
                case 0:
                    msg = this.lang['getStatusStr.status.0'];
                    break;
                case 1:
                    msg = this.lang['getStatusStr.status.1'];
                    break;
                case 3:
                    msg = this.lang['getStatusStr.status.3'];
                    break;
                default:
                    msg = this.lang['getStatusStr.status.default'];
            }
            return msg;
        },
    },
    methods:{
        //初始化值
        initModel(obj){
          this.type = obj.type;
          this.status = obj.status;
        },
        //通知父组件数据已变动
        sendFatherMsg(){
            this.$emit('changeData',{type:this.type,status:this.status})
        },
        //更改卡密类型
        changeType(type){
            this.type = type;
            this.sendFatherMsg();
        },
        //更改卡密状态
        changeStatus(status){
            this.status = status;
            this.sendFatherMsg();
        },
    },
}
</script>
<style scoped>

</style>