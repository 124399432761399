<template>
    <div class="tools">
        <el-button style="margin-right: 1px;margin-top: -2px" @click="this.$router.go(0)">
            {{lang['html.el-button.refresh']}}
        </el-button>

        <el-button plain style="margin-right: 1px;margin-top: -2px" @click="this.$refs.keyListFilterRef.openFilter()">{{lang['html.el-button.filter']}}</el-button>

        <el-button style="margin-right: 10px;margin-top: -2px" @click="makePdf">打印卡密[{{getKeyToPdfDataLength}}]</el-button>
        <!--        模式切换组件-->
        <key-list-tools-menu
            :select-table-arr="this.selectTableData"
            :agent-user-name="this.agent"
        />

        <key-list-mode-menu
            @changeData="changeTypeAndStatus"
            ref="keyListModelMenu"
        />
        <el-tag v-show="agent != 'self'">{{lang['html.el-tag.user']}}: {{agent}}</el-tag></div>

<!--    表格-->
    <el-table :data="tableData"
              style="width: 300%"
              stripe
              border
              @selection-change="handleSelectionChange"
              v-loading=isLoading
              element-loading-text="Loading..."
              element-loading-svg-view-box="-10, -10, 50, 50"
    >
        <el-table-column type="selection" width="50" />

        <el-table-column :label="lang['html.el-table-column.label.id']" width="105" highlight-current-row>
            <template #default="scope">
                {{ this.$helper.fixZeroStart(scope.row.id,9) }}
            </template>
        </el-table-column>

        <el-table-column label="Key" width="280" highlight-current-row>
            <template #default="scope">
                {{ scope.row.key }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.type']" width="80" highlight-current-row>
            <template #default="scope">
                {{ scope.row.type == 1 ? lang['scope.row.type.rec'] : lang['scope.row.type.reg'] }}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.status']" width="80" highlight-current-row>
            <template #default="scope">
                {{ getStatusStr(scope.row.status)}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.money']" width="90" highlight-current-row>
            <template #default="scope">
                {{ scope.row.money}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.nickname']" width="130" highlight-current-row>
            <template #default="scope">
                {{ scope.row.nickname}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.use']" width="130" highlight-current-row>
            <template #default="scope">
                {{ scope.row.use}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.time']"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.time}}
            </template>
        </el-table-column>

        <el-table-column label="Agent"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.agent}}
            </template>
        </el-table-column>
    </el-table>

<!--    筛选器组件-->
    <keyListFilter
        ref="keyListFilterRef"
        :key-type="this.keyModel.type"
        :agent-user-name="this.agent"
        @recv-filter-table-data="recvFilterTableData"
    />
<!--    分页组件-->
    <key-list-pagination
        ref="keyListPagination"
        @updateNowPage="recvChangePage"
        v-if="!filterMode"
    />

    <pagination-table-data
        :TableData = this.filterData
        @updateTableData="updateTableDataFormFilterData"
        v-show="filterMode"
    />
</template>

<script>
import keyListModeMenu from "./keyList/keyList-modeMenu";
import keyListPagination from "./keyList/keyList-pagination";
import keyListToolsMenu from "./keyList/keyList-ToolsMenu";
import keyListFilter from "./keyList/keyList-filter";
import {toBase64} from "js-base64";
import PaginationTableData from "../../components/PaginationTableData";

export default {
    name: "keyList",
    components:{
        PaginationTableData,
        keyListModeMenu,
        keyListPagination,
        keyListToolsMenu,
        keyListFilter,
    },
    data(){
        return {
            isLoading:true,
            //当前表格显示数据
            tableData:[],
            //执行筛选后的全部卡密数据
            filterData:[],
            //当前卡密模式
            keyModel:{
                //卡密类型[type]: 1=注册码 2=充值码 3=全部
                type:3,
                // 卡密状态[status]: 0=未使用 1=已使用 3=全部
                status:3,
            },
            //当前操作代理账号[self=自己]
            agent:'',
            //分页数据
            pageParams:{
                total:0,//当前卡密模式下的卡密总数
                nowPage:1,//当前页数
                pageSize:20,//每页显示数量,
            },
            selectTableData:[],//被多选的卡密
            filterMode:false,//当前是否为筛选模式,true=筛选模式
            lang:this.$lang.getLang('key','keyList'),
            keyToPdfData:[],//{key:null,//三十二位卡密idcard:null,//条形码text:null,//文本信息} //Ps:待生成pdf的卡密信息
        }
    },
    computed:{
        getKeyToPdfDataLength(){
            return this.keyToPdfData.length;
        }
    },
    methods:{
        //初始化
        initKeyList(){
            this.tableData = [];
            this.$message(this.lang['initKeyList.wait']);
            //初始化值
            let obj = this.$route.params;
            this.keyModel.type = Number(obj.type);
            this.keyModel.status = Number(obj.status);
            this.agent = obj.agent;
            //赋值给分页组件参数
            this.$refs.keyListModelMenu.initModel(this.keyModel)
            //获取当前类型卡密数量
            this.getTableTotal();
        },
        //[modelMenu]分页组件传来变动[卡密类型/卡密状态]消息
        changeTypeAndStatus(msgObj){
            console.log('changeTypeAndStatus',msgObj);
            if (msgObj != this.keyModel){
                this.$notify({message:this.lang['changeTypeAndStatus.wait'],type:"success"})
                this.keyModel = msgObj;
                //更改页面地址
                this.$router.push({name:'key.keyList',params:{agent:this.agent,type:this.keyModel.type,status:this.keyModel.status}});
                //延迟刷新页面,否则无效
                setTimeout(()=>{
                    this.$router.go(0);
                },1000)
            }
        },
        //通知分页组件,卡密总数已变动
        sendPagination(){
            this.$refs.keyListPagination.changeTotal(this.pageParams.total);
        },
        //收到分页组件传来消息,页数已变动
        recvChangePage(obj){
            this.pageParams.nowPage = obj.nowPage;
            this.pageParams.pageSize = obj.pageSize;
            //更新表格数据
            this.getTableData();
        },
        //获取当前卡密模式下的卡密总数
        getTableTotal(){
            this.$api.post('Key.KeyData/getAgentKeyCount',{
                type:this.keyModel.type,
                status:this.keyModel.status,
                agent:this.agent
            }).then(res=>{
                let data = this.$helper.checkRes(res,true);
                if(!data){
                    this.$alert(this.lang['getTableTotal.error.total']);
                    return;
                }
                this.pageParams.total = res.data.count ?? -1;
                this.sendPagination();
                //检测表格数据是否为空,是的话立马获取
                if (this.tableData.length == 0){
                    this.$notify({message:this.lang['getTableTotal.wait'],type:'info'})
                    this.getTableData();
                }
            })
        },
        //请求卡密数据
        getTableData(){
            this.isLoading = true;
            this.$api.post('Key.KeyData/getAgentKeyList',{
                type:this.keyModel.type,
                status:this.keyModel.status,
                page:this.pageParams.nowPage,
                listRows:this.pageParams.pageSize,
                agent:this.agent
            }).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res,true);
                if (!data){
                    this.$alert(this.lang['getTableData.error.render']);
                    return;
                }
                this.tableData = data.data;
            }).catch(err=>{
                this.$helper.axiosCatch(err);
                this.isLoading = false;
            })
        },
        //表格多选改动
        handleSelectionChange(selectArr) {
            this.selectTableData = selectArr;
            this.keyToPdfData = [];
            for (let selectArrKey in selectArr) {
                let temp = selectArr[selectArrKey];
                let idcard = this.$helper.fixZeroStart(temp['id'],9)
                this.keyToPdfData.push({
                    key:temp['key'],
                    idcard:idcard,
                    text:idcard,
                });
            }
        },
        //[status](表格)状态值转状态文本
        getStatusStr(status){
            let int = Number(status);
            return int === 1 ? this.lang['getStatusStr.1'] : this.lang['getStatusStr.default'];
        },
        //[筛选组件]接收筛选后的数据
        recvFilterTableData(obj){
            let length = obj.length;
            if(length > 0){
                this.$notify({message:this.lang['recvFilterTableData.tips.successful'] + length + this.lang['recvFilterTableData.tips.max'],type:'success'})
                this.tableData = [];
                this.filterMode = true;
                this.filterData =obj;
            }else{
                this.$alert(this.lang['recvFilterTableData.error.length']);
            }
        },
        //(筛选模式)[分页]更新表格内的数据
        updateTableDataFormFilterData(obj){
            this.tableData = obj;
        },
        makePdf(){
            this.isLoading = true;
            this.$message({message:this.lang['makePdf.tips.wait'],type:'info'});
            let str = JSON.stringify(this.keyToPdfData);
            let base64 = toBase64(str);
            let params = {keyData:base64};
            // let routeUrl = this.$router.resolve({name:'key.keyDataToPdf',params});
            // window.open(routeUrl.href, "_blank");
            this.$router.push({name:'key.keyDataToPdf',params});
        }
    },
    mounted() {
        this.initKeyList();
    }
}
</script>

<style scoped>
.tools {
    margin-bottom: 10px;
}

</style>