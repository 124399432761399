<template>
    <div style="text-align: left;display: inline">
        <el-pagination
            background
            layout="total,prev, pager, next,sizes"
            style="margin-top: 10px"
            v-show="pageShow"

            :total=total

            v-model:currentPage="nowPage"
            @current-change="changePage"

            v-model:page-size="pageSize"
            :page-sizes="[20,50,100,500,1000]"
            @size-change="handleSizeChange"
        />
        <el-tag>Pages: {{nowPage}} Sizes:{{pageSize}}</el-tag>
    </div>
</template>

<script>
export default {
    name: "keyList-pagination",
    emits:['updateNowPage'],
    data(){
        return {
            pageSize:20,//每页显示数量
            nowPage:1,//当前页数
            total:0,//数量总量
            pageShow:true,//是否显示本组件
        }
    },
    methods:{
        /**
         * 父组件传来消息,总数变动
         */
        changeTotal(num){
            this.total = Number(num);
        },
        //页数变动
        changePage(){
            //通知父组件页数已更改
            this.$emit('updateNowPage',{nowPage:this.nowPage,pageSize:this.pageSize});
            // console.log('changePage',this.nowPage)
        },
        //每页显示数量变动
        handleSizeChange(){
            this.nowPage = 1;
            this.changePage();
        },
    }
}
</script>

<style scoped>

</style>