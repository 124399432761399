<!--卡密列表工具栏-->
<template>
    <el-dropdown>
        <el-button class="el-dropdown-link" style="margin-right: 10px">
            Tools[{{selectTableArr.length}}]
            <el-icon class="el-icon--right">
                <arrow-down />
            </el-icon>
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="this.$router.go(0);">{{lang['html.el-dropdown-item.Refresh']}}</el-dropdown-item>
                <el-dropdown-item @click="outputSelectKey">{{lang['html.el-dropdown-item.outputSelectKey']}}</el-dropdown-item>

<!--                [迁移卡密]和[回收卡密]只能是操作自己账号时候使用-->
                <el-dropdown-item @click="moveKey" v-if = "agentUserName == 'self'" >
                    {{lang['html.el-dropdown-item.moveKey']}}
                </el-dropdown-item>

                <el-dropdown-item @click="recoveryKey" v-if = "agentUserName == 'self'" >
                    {{lang['html.el-dropdown-item.recoveryKey']}}
                </el-dropdown-item>

<!--                [回迁卡密]只能在操作下级代理商时候调用-->
                <el-dropdown-item @click="takeBackKey" v-if = "agentUserName != 'self'" >
                    {{lang['html.el-dropdown-item.takeBackKey']}}
                </el-dropdown-item>

            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script>
import lib from "../../../utils/common";
import {toBase64} from "js-base64";

export default {
    name: "keyList-ToolsMenu",
    data(){
        return {
            lang:this.$lang.getLang('key','keyList-ToolsMenu')
        }
    },
    props:[
        'selectTableArr',//表格被多选的代理数组
        'agentUserName',//当前操作的代理账号
    ],
    methods:{
        /**
         * 获取当前选中的卡密,成功返回数组,失败返回null并弹出错误信息框
         * @returns {null|*}
         */
        getSelectArr(){
            let arr = this.selectTableArr;
            if (arr.length <= 0){
                this.$alert(this.lang['getSelectArr.tips']);
                return null;
            }
            return arr;
        },
        //导出选中卡密
        outputSelectKey(){
            let arr = this.getSelectArr();
            if (!arr){
                return;
            }
            this.$notify(this.lang['outputSelectKey.wait']);
            let enter = '\r\n';
            let msg = '';
            let headerArr = ['id','key','type','status','money','nickname','use','time'];
            let header = headerArr.toString();
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                let res = [
                    temp['id'],
                    temp['key'],
                    temp['type'],
                    temp['status'],
                    temp['money'],
                    temp['nickname'],
                    temp['use'],
                    temp['time'],
                ];
                msg += res.toString() + enter
            }
            let result = header + enter + msg;
            lib.downLoad(result, 'keyList.csv');
        },

        //迁移卡密[自己->下级代理商]
        moveKey(){
            let arr = this.getSelectArr();
            if (!arr){
                return;
            }
            this.$notify(this.lang['moveKey.wait']);
            let res = [];
            for (let arrKey in arr) {
                let temp = arr[arrKey].key;
                res.push(temp);
            }
            let str = toBase64(res.toString());
            this.$router.push({name:'key.moveKey',params:{keyList:str}})
        },

        //回收卡密[回收自己的卡密,不是回收下级的]
        recoveryKey(){
            let arr = this.getSelectArr();
            if (!arr){
                return;
            }
            this.$notify(this.lang['recoveryKey.wait']);
            let res = [];
            for (let arrKey in arr) {
                let temp = arr[arrKey].key;
                res.push(temp);
            }
            let str = toBase64(res.toString());
            this.$router.push({name:'key.recovery',params:{keyList:str}})
        },

        //迁回卡密[下级代理商->自己]
        takeBackKey(){
            let arr = this.getSelectArr();
            if (!arr){
                return;
            }
            this.$notify(this.lang['takeBackKey.wait']);
            let res = [];
            for (let arrKey in arr) {
                let temp = arr[arrKey].key;
                res.push(temp);
            }
            let str = toBase64(res.toString());
            this.$router.push({name:'key.keyTackBackKey',params:{keyList:str}})
            console.log('takeBackKey',arr);
        },
    },
}
</script>

<style scoped>

</style>