<template>
    <el-dialog
        v-model="dialogVisible"
        title="Filter"
        width="30%"
    >
        <el-form v-loading="isLoading">
            <el-form-item :label="lang['html.el-form-item.key']">
                <el-input v-model="form.key" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.id']">
                <el-input v-model="form.id" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.use']">
                <el-input v-model="form.use" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.nickname']">
                <el-input v-model="form.nickname" />
            </el-form-item>

            <el-form-item :label="lang['html.el-form-item.time']">
                <el-date-picker
                    v-model="form.time"
                    type="datetimerange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    value-format="YYYY-MM-DD HH:mm:ss"
                />
            </el-form-item>
        </el-form>

        <el-tag>{{lang['html.el-tag.tips']}}</el-tag>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="submit" :disabled="isDisabled">{{lang['html.button.submit']}}</el-button>
            <el-button type="primary" @click="dialogVisible = false;clearAll()" :disabled="isDisabled">{{lang['html.button.return']}}</el-button>
            <el-button type="danger" @click="clearAll()" :disabled="isDisabled">{{lang['html.button.resetForm']}}</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
export default {
    name: "keyList-filter",
    props:[
        'keyType',//卡密类型[type]: 1=注册码 2=充值码 3=全部
        'agentUserName',//当前操作代理商账号[self=自己]
    ],
    emits:[
        'recvFilterTableData',//通知父组件接收筛选后的表格数据
    ],
    data(){
        return {
            dialogVisible:false,
            filterConfig:{
                // [筛选类型] 1.卡密 2.使用者 3.条形码 4.操作时间 5.卡密备注
                type:0,
                //筛选值
                value:null,
            },
            form:{
                id:null,
                use:null,
                key:null,
                time:null,
                nickname:null,
            },
            isLoading:false,
            isDisabled:false,
            lang:this.$lang.getLang('key','keyList-filter')
        }
    },
    methods:{
        //打开筛选框界面
        openFilter(){
            this.dialogVisible = true;
        },
        clearAll(){
            this.form = {id:null, use:null, key:null, time:null,};
            this.filterConfig = {type:0, value:null,};
        },
        //提交筛选请求
        submit(){
            let bool = this.setFilterConfig();
            if (!bool){
                this.$alert(this.lang['submit.error.filterConfig']);
                return;
            }
            this.isLoading = this.isDisabled = true;
            this.$message(this.lang['submit.wait']);
            this.getFilterTableData();
        },
        //获取筛选后的数据
        getFilterTableData(){
            this.$api.post('Key.KeyData/filterKey',{
                type:this.keyType,
                filterType:this.filterConfig.type,
                filterValue:this.filterConfig.value,
                agent:this.agentUserName,
            }).then(res=>{
                this.isLoading = this.isDisabled = false;
                let data = this.$helper.checkRes(res);
                if(!data){
                    this.$alert(this.lang['getFilterTableData.error.filter.1']);
                    return;
                }
                //成功
                let filterData = data.data;
                if (filterData){
                    //通知父组件接收筛选后的数据
                    this.$emit('recvFilterTableData', filterData);
                }else {
                    this.$alert(this.lang['getFilterTableData.error.filter.2'])
                }
                this.clearAll();
                this.dialogVisible = false;
            }).catch(err=>{
                this.$helper.axiosCatch(err);
                this.isLoading = this.isDisabled = false;
                this.clearAll();
                this.dialogVisible = false;
            })
        },
        /**
         * 设置筛选配置
         * @returns {boolean}
         * 成功返回true
         */
        setFilterConfig(){
            let bool = false;
            let arr = this.form;
            for (let arrKey in arr) {
                let temp = arr[arrKey];
                if (temp != null){
                    bool = true;
                    this.filterConfig.type = this.getFilterType(arrKey);
                    this.filterConfig.value = temp;
                    break;
                }
            }
            if (bool){
                if (this.filterConfig.type === 4){
                    //如果是时间类型则需要转换
                    this.filterConfig.value = this.filterConfig.value.toString();
                }
            }
            // console.log('setFilterConfig',bool,this.filterConfig.type,this.filterConfig.value)
            return bool;
        },
        /**
         * 类型名字取类型值
         * @param typeName 类型英文
         * @returns {number}
         * [筛选类型] 1.卡密 2.使用者 3.条形码 4.操作时间
         */
        getFilterType(typeName){
            let type = 0;
            switch (typeName){
                case 'key':
                    type = 1;
                    break;
                case 'use':
                    type = 2;
                    break;
                case 'id':
                    type = 3;
                    break;
                case 'time':
                    type = 4;
                    break;
                case 'nickname':
                    type = 5;
                    break;
            }
            return type;
        }
    },
}
</script>

<style scoped>

</style>